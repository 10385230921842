<template>
  <vca-card>
    <h2>{{ $t("email.edit.header") }}</h2>
    <EmailEdit />
  </vca-card>
</template>

<script>
import { mapGetters } from "vuex";
import EmailEdit from "@/components/account/emails/AccountAspEmailsEdit";
export default {
  name: "EmailsEdit",
  components: { EmailEdit },
  created() {
    this.$store.commit("user/aspevents/list", []);
    this.$store.dispatch({ type: "events/listAsp" }).then((response) => {
      const eventList =
        !response || response.length == 0
          ? []
          : response.filter((el) => {
              el.is_event_asp = true;
              return true;
            });
      this.$store.commit("user/aspevents/list", eventList);
      this.$store.commit("user/aspevents/pg/pageSize", eventList.length);
    });
    this.$store.dispatch("user/emails/mailbox/get").then(() => {
      var mailbox = this.getMailbox(this.$route.params.id);
      if (mailbox != "draft") {
        this.notification({
          title: this.$t("messages.title.warn"),
          body: this.$t("messages.message.not_editable"),
          type: "warn",
        });

        //this.$router.push("/account/asps/emails");
      } else {
        this.$store.dispatch({
          type: "user/emails/mailbox/message/get",
          id: this.$route.params.id,
        });
      }
    });
  },
  computed: {
    ...mapGetters({
      getMailbox: "user/emails/mailbox/getMailbox",
    }),
  },
};
</script>
